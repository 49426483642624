import React, { Suspense, lazy, useEffect, useContext } from "react";
import TagManager from "react-gtm-module";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import "./assets/css/colors.scss";

import CircularProgress from "@mui/material/CircularProgress";
import { Toaster } from "react-hot-toast";
import "./assets/css/app.scss";
import RedirectHandler from "./components/SMredirect/SMredirect";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Sidebar from "./components/Sidebar/Sidebar";
import SidebarRight from "./components/SidebarRight/SidebarRight";
import Footer from "./components/footer/Footer";
import Navigation from "./components/navigation/Navigation/Navigation";
import AuthService from "./services/auth.service";
import Store from "./store/store";
import { DarkModeContext } from "../src/utils/darkMode";

// REDUX ACTIONS
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { notificationsList } from "./redux/actions/notifications";
import { dark } from "@mui/material/styles/createPalette";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

const Subscriptions = lazy(() => import("./pages/Subscriptions/Subscriptions"));
const Friends = lazy(() => import("./pages/Profile/AppFriends"));
const Following = lazy(() => import("./pages/Following/Following"));
const HomepageV4 = lazy(() => import("./pages/Homepage/Homepage"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const PageNotFound = lazy(() => import("./pages/404/PageNotFound"));
const Discover = lazy(() => import("./pages/Discover/Discover"));
const Explore = lazy(() => import("./pages/Explore/Explore"));
// const AppVideo = lazy(() => import("./pages/NotBeingUsed/AppVideo"));
const ChannelPage = lazy(() => import("./pages/Channel/ChannelPage"));
const UserProfileSettings = lazy(() =>
  import("./pages/Profile/UserProfileSettings/UserProfileSettings")
);
const StreamerBase = lazy(() => import("./pages/StreamerDashboard/Base"));
const ChannelOnboard = lazy(() =>
  import("./pages/ChannelOnboarding/OnboardChannel/OnboardChannel")
);
const ChannelList = lazy(() => import("./pages/ChannelOnboarding/ChannelList"));
const ActivateAccount = lazy(() => import("./pages/Profile/ActivateAccount"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const UserPage = lazy(() => import("./pages/UserPage/UserPage"));
// const ScrollToTop = lazy(() => import("./components/ScrollToTop/ScrollToTop"));
const Faq = lazy(() => import("./pages/Faq/Faq"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse/TermsOfUse"));
const OurStory = lazy(() => import("./pages/OurStory/OurStory"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const Search = lazy(() => import("./pages/Search/Search"));
const StreamPage = lazy(() => import("./pages/StreamPage/DashboardStream"));
const VideoPage = lazy(() => import("./pages/StreamPage/VideoPage"));

const tagManagerArgs = {
  gtmId: "GTM-5R4KKG8",
};

TagManager.initialize(tagManagerArgs);

function External() {
  window.location.href =
    "https://drive.google.com/file/d/1v3onM-wZjUuSgnNiFVE8V70L0OCGcinG/view";
  return null;
}

function App({ notificationsList }) {
  //Was causing unecessary padding on the
  // document.addEventListener("DOMContentLoaded", function () {
  //   // add padding top to show content behind navbar
  //   let navbar_height =
  //     document.querySelector(".Navigation_nav__Zchl2")?.offsetHeight || 0;
  //   let mainSection = document.getElementById("mainSection");
  //   // if (mainSection) mainSection.style.paddingTop = navbar_height + 'px';
  //   // .style.paddingTop = navbar_height + 'px';
  // });
  const match = useMatch("/");
  const aboutusMatch = useMatch("/about-us");
  const contactMatch = useMatch("/contact");
  const privacyMatch = useMatch("/privacy-policy");
  const termsMatch = useMatch("/terms-of-use");
  const faqMatch = useMatch("faq");

  const { darkMode } = useContext(DarkModeContext);

  const user = AuthService.getCurrentUser();

  const getNotifications = async () => {
    if (user?.access)
      await AuthService.getNotifications().then((data) =>
        notificationsList(data && data.results)
      );
  };

  useEffect(() => {
    getNotifications();

    // Only set the interval if notificationsList is not empty
    // if (notificationsList && notificationsList.length > 0) {
    //   const intervalId = window.setInterval(function () {
    //     getNotifications();
    //   }, 15000);
    //   return function cleanup() {
    //     clearInterval(intervalId);
    //   };
    // }
  }, [notificationsList, getNotifications, user?.access]);

  const PrivateRoute = ({ children }) => {
    const user = AuthService.getCurrentUser();
    if (user?.access) return children;
    else return <Navigate to={"/"} />;
  };

  return (
    <>
      <Toaster />
      <Store>
        <div id="baseWrapper">
          <Navigation triggerGetNofitication={(e) => getNotifications()} />
          {/* <CircularProgress className="loader" id="loader-politix" /> */}
          <div
            id="mainSection"
            className="mainSection"
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: darkMode ? "#2c2c38" : "#012060",
            }}
          >
            {!aboutusMatch &&
              !contactMatch &&
              !faqMatch &&
              !privacyMatch &&
              !termsMatch && <Sidebar/>}
            <Suspense
              fallback={
                <CircularProgress className="loader" id="loader-politix" />
              }
            >
              <Routes>
                <Route exact path="/" element={<HomepageV4 />} />
                <Route path="/redirect" element={<RedirectHandler />} />
                <Route exact path="/discover/" element={<Discover />} />
                <Route exact path="/explore/" element={<Explore />} />
                {/* <Route exact path="/video/:videoId/" element={<AppVideo />} /> */}
                <Route
                  exact
                  path="/channel/:channelId/"
                  element={<ChannelPage darkMode={darkMode} />}
                />
                <Route
                  exact
                  path="/profile/me/:channelId/"
                  element={
                    <PrivateRoute>
                      <UserProfileSettings darkMode={darkMode} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/profile/me/settings"
                  element={
                    <PrivateRoute>
                      <UserProfileSettings darkMode={darkMode} />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  exact
                  path="/channel/:channelId/streamPage"
                  element={
                    <PrivateRoute>
                      <StreamPage />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  exact
                  path="/Following"
                  element={
                    <PrivateRoute>
                      <Following darkMode={darkMode} />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/subscriptions"
                  element={
                    <PrivateRoute>
                      <Subscriptions darkMode={darkMode} />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path="/friends"
                  element={
                    <PrivateRoute>
                      <Friends />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/channel/:channelId/:recordedStreamID"
                  element={
                    <div
                      style={{
                        backgroundColor: darkMode ? "#151521" : "#DEE4FC",
                        width: "100vw",
                      }}
                    >
                      <VideoPage darkMode={darkMode} />
                    </div>
                  }
                />
                <Route
                  exact
                  path="/profile/user/:username/"
                  element={<UserPage />}
                />
                <Route
                  exact
                  path="/live/channel-onboard/"
                  element={
                    <PrivateRoute>
                      <ChannelOnboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/live/channel-list/"
                  element={
                    <PrivateRoute>
                      <ChannelOnboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/streamer/:channelId/*"
                  element={
                    <PrivateRoute>
                      <StreamerBase />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/activate-account/"
                  element={<ActivateAccount />}
                />
                <Route
                  exact
                  path="/reset-password/"
                  element={<ResetPassword />}
                />
                <Route exact path="/our-story" element={<OurStory />} />
                <Route exact path="/terms-of-use" element={<TermsOfUse />} />
                <Route exact path="/faq" element={<Faq />} />
                <Route
                  exact
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                />
                <Route exact path="/contact" element={<Contact />} />
                <Route
                  exact
                  path="/search"
                  element={<Search darkMode={darkMode} />}
                />
                <Route exact path="/about-us" element={<AboutUs />} />
                <Route path="*" element={<PageNotFound />} />
                <Route exact path="/streamerguide" element={<External />} />
              </Routes>
            </Suspense>
            {match && <SidebarRight darkMode={darkMode} />}
          </div>
          <Footer />
        </div>
      </Store>

      <ScrollToTop />
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      notificationsList,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
